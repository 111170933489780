import { useNotification } from '@/composable/useNotifications';
import RepairPartApiService from '@/services/api/RepairPartApiService';
import RepairPart from '@/domain/Repair/RepairPart';
import RepairPartInventory from '@/domain/Repair/RepairPartInventory';
import { getTranslation } from '@/services/TranslationService';

export default class RepairPartService {
    private repairPartApiService: RepairPartApiService;
    private notification = useNotification();

    constructor() {
        this.repairPartApiService = new RepairPartApiService();
    }

    public async getAllRepairParts(): Promise<Array<RepairPart> | null> {
        const response = await this.repairPartApiService.getAllRepairParts();
        return response.success ? response.data : null;
    }

    public async getRepairPartById(id: number): Promise<RepairPart | null> {
        const response = await this.repairPartApiService.getRepairPartById(id);
        return response.success ? response.data : null;
    }

    public async deleteRepairPart(id: number): Promise<boolean> {
        const response = await this.repairPartApiService.deleteRepairPart(id);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.repairPartDeleted'));
        }
        return response.success;
    }

    public async addRepairPart(repairPart: RepairPart): Promise<{ success: boolean; id: number }> {
        const response = await this.repairPartApiService.addRepairPart(repairPart);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.repairPartAdded'));
        }
        return { success: response.success, id: response.data };
    }

    public async updateRepairPart(repairPart: RepairPart): Promise<{ success: boolean; id: number }> {
        const response = await this.repairPartApiService.updateRepairPart(repairPart);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.repairPartUpdated'));
        }
        return { success: response.success, id: repairPart.id };
    }

    public async getRepairPartInventoryByRepairPartId(id: number): Promise<Array<RepairPartInventory> | null> {
        const response = await this.repairPartApiService.getRepairPartInventoryByRepairPartId(id);
        return response.success ? response.data : null;
    }

    public async getRepairPartInventoryById(id: number): Promise<RepairPartInventory | null> {
        const response = await this.repairPartApiService.getRepairPartInventoryById(id);
        return response.success ? response.data : null;
    }

    public async addRepairPartInventory(repairPartInventory: RepairPartInventory): Promise<{ success: boolean; id: number }> {
        const response = await this.repairPartApiService.addRepairPartInventory(repairPartInventory);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.repairPartInventoryAdded'));
        }
        return { success: response.success, id: response.data };
    }

    public async updateRepairPartInventory(repairPartInventory: RepairPartInventory): Promise<{ success: boolean; id: number }> {
        const response = await this.repairPartApiService.updateRepairPartInventory(repairPartInventory);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.repairPartInventoryUpdated'));
        }
        return { success: response.success, id: repairPartInventory.id };
    }
}
